var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-3" },
    [
      _c("mew-stepper", {
        attrs: { items: _vm.stepperItems, "on-step": _vm.onStep },
        on: { onContinue: _vm.nextStep },
        scopedSlots: _vm._u(
          [
            _vm.onStep === 1
              ? {
                  key: "stepperContent1",
                  fn: function () {
                    return [
                      _c("step-one-amount", {
                        attrs: { "current-apr": _vm.currentApr },
                        on: { onContinue: _vm.nextStep },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.onStep === 2
              ? {
                  key: "stepperContent2",
                  fn: function () {
                    return [
                      _c("step-two-generate", {
                        attrs: { skipped: _vm.skipped },
                        on: { onContinue: _vm.nextStep, back: _vm.back },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.onStep === 3
              ? {
                  key: "stepperContent3",
                  fn: function () {
                    return [
                      _c("step-three-upload", {
                        attrs: { skipped: _vm.skipped, address: _vm.address },
                        on: { onContinue: _vm.nextStep, back: _vm.back },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.onStep === 4
              ? {
                  key: "stepperContent4",
                  fn: function () {
                    return [
                      _c("step-four-review", {
                        attrs: {
                          amount: _vm.amount,
                          "eth2-address": _vm.address,
                          "start-provision": _vm.startProvision,
                          "polling-status": _vm.pollingStatus,
                        },
                        on: { readyToStake: _vm.readyToStake, back: _vm.back },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }